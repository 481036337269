import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, of} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, flatMap} from 'rxjs/operators';
import {SharedService} from '../../services/shared.service';

@Component({
  selector: 'app-user-search-field',
  templateUrl: './user-search-field.component.html',
  styleUrls: ['./user-search-field.component.scss']
})
export class UserSearchFieldComponent implements OnInit {
  @Input() selectedUsers = [] as Array<any>;
  @Input() gid: number;
  @Input() offset = '50 0';
  @Input() searchMode = 'activeUsers'; // or 'noFuncUsers'
  @Input() width = '100%';
  @Output() onUsersSelected = new EventEmitter();
  public searchUsers$ = (text: string): Observable<any> => {
    return of(text).pipe(
      filter(val => val.trim() !== '' && val.trim().length >= 2),
      debounceTime(200),
      distinctUntilChanged(),
      flatMap(val => {
        if (this.searchMode === 'noFuncUsers') {
          return this.sharedService.searchUnassignedUsers(val).pipe(
            flatMap((res: any) => {
              this.offset = this.getOffset(res.data.length);
              this.cdRef.detectChanges();
              return of(res.data);
            })
          );
        } else if (this.searchMode === 'groupUsers' &&
          this.gid) {
          return this.sharedService
            .searchUsersForGroup(val, this.gid)
            .pipe(
              flatMap((res: any) => {
                this.offset = this.getOffset(res.data.length);
                this.cdRef.detectChanges();
                return of(res.data);
              })
            );
        } else {
          return this.sharedService.searchActiveUsers(val).pipe(
            flatMap((res: any) => {
              this.offset = this.getOffset(res.data.length);
              this.cdRef.detectChanges();
              return of(res.data);
            })
          );
        }
      })
    );
  };

  constructor(private sharedService: SharedService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  public usersSelected() {
    this.onUsersSelected.emit(this.selectedUsers);
  }

  private getOffset(l) {
    return (l <= 3) ? '-50 0' :
      ((l > 3 && l <= 8)
        ? '-' + l.toString() + ' 0'
        : '-340 0');
  }

}

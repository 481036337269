import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { tap, debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Location } from "@angular/common";

@Component({
  selector: 'app-custom-skf-card',
  templateUrl: './custom-skf-card.component.html',
  styleUrls: ['./custom-skf-card.component.scss']
})
export class CustomSkfCardComponent implements OnInit {
  @Input() title: string;
  @Input() showBackArrow = false;
  @Input() tableMode = false;
  @Input() isActionBtn = false;
  @Input() actionTitle: string;
  @Input() actionToolTip: string;
  @Input() actionIcon: string;
  searchControl = new FormControl();
  @Output() searchEvent = new EventEmitter<string>();
  @Output() handleActionClick = new EventEmitter();

  constructor(private location: Location) {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.tableMode) {
      this.searchControl.valueChanges
        .pipe(
          tap((val) => {
            if (val === "") {
              this.searchEvent.emit(val);
            }
          }),
          debounceTime(1000),
          distinctUntilChanged(),
          filter(
            (val) =>
              val.trim() !== "" && val.trim().length >= 3 && val.length <= 3998
          ),
          map((val) => val.replace(/[`%^{}\\|]/g, "")),
          debounceTime(500)
        )
        .subscribe((val) => this.searchEvent.emit(val));
    }
  }

  goBack() {
    this.location.back();
  }

  emitClear() {
    this.searchControl.setValue("");
    // this.searchEvent.emit("");
  }

  public doActionClick() {
    this.handleActionClick.emit();
  }

}

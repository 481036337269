import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, of} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, flatMap} from 'rxjs/operators';
import {SharedService} from '@app/services/shared.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-group-search-field',
  templateUrl: './group-search-field.component.html'
})
export class GroupSearchFieldComponent implements OnInit {

  @Input() selectedGroups = [] as Array<any>;
  @Input() placeholder = 'Add Groups';
  @Input() required = false;
  @Input() readonly = false;
  @Input() width = '100%';
  @Output() onGroupsSelected = new EventEmitter();
  public modalViewGroups = [] as Array<any>;
  public searchModalRef: NgbModalRef;
  public inError = false;
  public canShowGroupSearch: boolean;

  constructor(public sharedService: SharedService,
              public modalService: NgbModal) {
  }

  ngOnInit() {
  }

  public groupsSelected(event?: Array<any>) {
    event ? this.onGroupsSelected.emit(event)
      : this.onGroupsSelected.emit(this.selectedGroups);
  }

  public checkError() {
    if (this.required) {
      this.inError = this.selectedGroups.length === 0;
    }
  }

  searchApiAccessor(val: string): Observable<any> {
    return this.sharedService.searchGroups(val).pipe(
      flatMap((res: any) => {
        return of(res.data);
      })
    );
  }

  public doScrollEnable(event: boolean) {
    this.sharedService.scrollDisabledSubject.next(event);
  }

  public openSearchModalView(template) {
    this.canShowGroupSearch = false;
    this.modalViewGroups = [...this.selectedGroups];
    this.searchModalRef = this.modalService.open(template);
    setTimeout(() => {
      this.canShowGroupSearch = true;
    }, 10);
  }

  public closeModal() {
    this.searchModalRef.close();
  }

  public updateSelectedGroups(update: boolean) {
    if (update) {
      this.selectedGroups = this.modalViewGroups;
      this.groupsSelected();
    }
    this.closeModal();
  }

  public updateModalViewGroups(event) {
    this.modalViewGroups = event;
  }

}

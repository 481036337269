<section class="skf-card-body-grey">
  <div class="heading-box">
    <div class="heading">
      <span class="heading-content"> {{cardTitle|translate|uppercase}}</span>
      <span *ngIf="entityTitle" class="desc-content">
        ‘{{entityTitle|translate}}’
      </span>
    </div>
    <!-- <div class="view-mode-btn">
  <button class="view-mode">
    View Mode
  </button>
</div> -->
  </div>
  <div class="skf-grey-card-content">
    <div class="skf-detail-box" *ngFor="let detail of viewDetails">
      <ng-container [ngSwitch]="detail.type">
        <label class="skf-label">{{detail.label|translate|titlecase}}</label>
        <ng-container *ngSwitchCase="'string'">
          <div class="content-detail" [class.bold]="detail.isBold">
            {{detail.value}}
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'chip'">
          <div class="content-detail">
            <div *ngIf="detail?.value?.length>0" class="grey-chip">
              <span *ngFor="let val of detail?.value" class="chip">{{val}}</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'date'">
          <div class="content-detail" [class.bold]="detail.isBold">
            {{detail.value|date:dateFormat}}
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'link'">
          <div class="content-detail" [class.bold]="detail.isBold">
            <a [href]="detail.value" target="_blank">{{detail.value}}</a>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'to'">
          <div class="content-detail">
            <div class="group-box">
              <div *ngIf="detail?.value?.groups?.length>0;else NA" class="grey-chip">
                <span *ngFor="let group of detail?.value?.groups" class="chip">{{group}}</span>
              </div>
              <div class="head">
                {{'Groups'|translate}}
              </div>
            </div>
            <div class="function-box">
              <div *ngIf="detail?.value?.functions?.length>0;else NA" class="grey-chip">
                <span *ngFor="let func of detail?.value?.functions" class="chip">{{func}}</span>
              </div>
              <div class="head">
                {{'Functions'|translate}}
              </div>
            </div>
            <ng-template #NA><span>{{'NA'|translate}}</span></ng-template>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'content'">
          <ng-container *ngTemplateOutlet="detail.value"></ng-container>
        </ng-container>
      </ng-container>
    </div>
    <ng-content></ng-content>
  </div>
</section>

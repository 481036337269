import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {AdminComponent} from './layouts/admin/admin.component';
import {SharedModule} from './shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {InterceptorService} from './services/interceptor.service';

import {ToastrModule} from 'ngx-toastr';
import {NgxUiLoaderConfig, NgxUiLoaderModule} from 'ngx-ui-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';

import {LoaderService} from './services/loader.service';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SKFAuthModule} from '@skf/auth';
import {authConfig} from '@app/_config/authConfig';
import {SKFUICommonService, SKFUILayoutModule} from '@skf/ui';

const ngxloaderConfig =
  {
    bgsColor: '#0f58d6',
    bgsOpacity: 0,
    bgsPosition: 'bottom-right',
    bgsSize: 20,
    bgsType: 'ball-spin-clockwise',
    blur: 0,
    delay: 0,
    fastFadeOut: true,
    fgsColor: '#0f58d6',
    fgsPosition: 'center-center',
    fgsSize: 50,
    fgsType: 'three-strings',
    gap: 24,
    logoPosition: 'center-center',
    logoSize: 120,
    logoUrl: '',
    masterLoaderId: 'master',
    overlayBorderRadius: '0',
    overlayColor: 'rgba(255,255,255,0)',
    pbColor: 'red',
    pbDirection: 'ltr',
    pbThickness: 3,
    hasProgressBar: false,
    text: '',
    textColor: '#FFFFFF',
    textPosition: 'center-center',
    maxTime: -1,
    minTime: 300
  } as NgxUiLoaderConfig;

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    ToastrModule.forRoot({maxOpened:1, autoDismiss:true}),
    NgxUiLoaderModule.forRoot(ngxloaderConfig),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
        deps: [HttpClient]
      }
    }),
    SKFAuthModule.forRoot(authConfig),
    SKFUILayoutModule
  ],
  providers: [
    LoaderService,
    SKFUICommonService,
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule {
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';

import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ToastrModule} from 'ngx-toastr';
import {NgxSortableModule} from 'ngx-sortable';

import {SkfUiModule} from '../skf-ui/skf-ui.module';
import {MaterialModule} from './material.module';
import {ModalComponent} from './modal/modal.component';
import {GroupSearchFieldComponent} from './group-search-field/group-search-field.component';
import {FunctionsSearchFieldComponent} from './functions-search-field/functions-search-field.component';
import {TimeoffSearchFieldComponent} from './timeoff-search-field/timeoff-search-field.component';
import {MenuItems} from './menu-items/menu-items';
import {UserSearchFieldComponent} from './user-search-field/user-search-field.component';
import {TranslateModule} from '@ngx-translate/core';
import {
  SKFUIAutocompleteModule,
  SKFUICardModule,
  SKFUICommonModule,
  SKFUIDataTableModule,
  SKFUIInputModule,
  SKFUIModalModule
} from '@skf/ui';
import { CustomSkfCardComponent } from './custom-skf-card/custom-skf-card.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        FlexLayoutModule,
        NgbModule,
        PerfectScrollbarModule,
        ToastrModule,
        NgxSortableModule,
        MaterialModule,
        SkfUiModule,
        TranslateModule.forChild({ extend: true }),
        SKFUIModalModule,
        SKFUICardModule,
        SKFUIInputModule,
        SKFUIAutocompleteModule,
        SKFUICommonModule,
        SKFUIDataTableModule
    ],
    declarations: [
        ModalComponent,
        GroupSearchFieldComponent,
        FunctionsSearchFieldComponent,
        UserSearchFieldComponent,
        TimeoffSearchFieldComponent,
        CustomSkfCardComponent
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        NgbModule,
        NgxSortableModule,
        PerfectScrollbarModule,
        ToastrModule,
        MaterialModule,
        SkfUiModule,
        ModalComponent,
        GroupSearchFieldComponent,
        FunctionsSearchFieldComponent,
        TimeoffSearchFieldComponent,
        TranslateModule,
        SKFUIModalModule,
        SKFUICardModule,
        SKFUIInputModule,
        SKFUIAutocompleteModule,
        SKFUICommonModule,
        SKFUIDataTableModule,
        CustomSkfCardComponent
    ],
    providers: [
        MenuItems,
        NgbActiveModal,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ]
})
export class SharedModule {
}

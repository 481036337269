<ng-container *ngIf="type==='content';else dialogModal">
  <div class="modal-wrapper">
    <a (click)="onClose()" class="close-btn">
      <mat-icon>close</mat-icon>
    </a>
    <div class="header" *ngIf="title">{{title|translate|titlecase}}</div>
    <div class="modal-content-box custom-content">
      <ng-content></ng-content>
      <p class="text-start" *ngIf="dialog">{{dialog|translate}}</p>
      <div class="actions"
           fxLayoutAlign="start"
           fxLayoutGap="10px">
        <app-skf-button [content]="confirmText"
                        [isRounded]="false"
                        [disabled]="isNotValid"
                        (btnClick)="onConfirm()"></app-skf-button>
        <app-skf-button [content]="cancelText"
                        [isRounded]="false"
                        theme="link"
                        (btnClick)="onCancel()"></app-skf-button>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #dialogModal>
  <div class="modal-wrapper">
    <a (click)="onClose()" class="close-btn">
      <mat-icon>close</mat-icon>
    </a>
    <div class="modal-content-box">
      <mat-icon *ngIf="icon">{{icon}}</mat-icon>
      <p class="notification-content">{{title|translate|titlecase}}</p>
    </div>
    <div class="footer-box">
      <p>{{dialog|translate}}</p>
      <div class="actions"
           fxLayoutAlign="center"
           fxLayoutGap="10px">
        <app-skf-button [content]="cancelText"
                        [isRounded]="false"
                        theme="grey"
                        (btnClick)="onCancel()"></app-skf-button>
        <app-skf-button [content]="confirmText"
                        [disabled]="isNotValid"
                        [isRounded]="false"
                        (btnClick)="onConfirm()"></app-skf-button>
      </div>
    </div>
  </div>
</ng-template>

<lib-skf-ui-autocomplete label="Groups"
                         [placeholder]="placeholder"
                         secondaryPlaceholder="Add More Groups"
                         [idKey]="'id'"
                         [labelKey]="'name'"
                         [required]="required"
                         [readonly]="readonly"
                         [modalMode]="true"
                         (emitToggleModalView)="openSearchModalView(searchModalView)"
                         (emitSelectedItems)="groupsSelected($event)"
                         (scrollDisable)="doScrollEnable($event)"
                         [selectedItems]="selectedGroups"
                         [autoCompleteFunction]="searchApiAccessor.bind(this)"
></lib-skf-ui-autocomplete>

<ng-template #searchModalView>
  <app-modal [action]="'Confirm'"
             type="content"
             [isValid]="modalViewGroups?.length>0"
             (dismiss)="closeModal()"
             (proceed)="updateSelectedGroups($event)"
  >
    <lib-skf-ui-search-modal-view
      *ngIf="canShowGroupSearch"
      name="groups"
      [selectedItems]="modalViewGroups"
      [autoCompleteFunction]="searchApiAccessor.bind(this)"
      (emitSelectedItems)="updateModalViewGroups($event)"
    ></lib-skf-ui-search-modal-view>
  </app-modal>
</ng-template>

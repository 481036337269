import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {availableLocales} from '@app/_config/locale';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private router: Router,
              private translate: TranslateService) {
    translate.addLangs(Array.from(availableLocales.keys()));
    // fetch and use user's preferred lang
    const preferredLang = localStorage.getItem('lang');
    /* TODO: uncomment when backend starts sending values
    const preferredLang = this.sharedService.userDetails.GlobalUserPreferenceLanguageISOCode;*/
    translate.use(preferredLang ? preferredLang : 'en');
  }
}

import {Component} from '@angular/core';
import {SKFFooterInputDetails, SKFHeaderInputDetails, SKFLayoutInputDetails, SKFUICommonService} from '@skf/ui';
import {SKFAuthService} from '@skf/auth';
import {menuItems} from '@app/_config/menuItems';
import {moduleLabels} from '@app/_config/moduleConfig';
import {NavigationEnd, Router} from '@angular/router';
@Component({
  selector: 'app-admin',
  template: `
    <lib-skf-ui-layout (logoutEvent)="logout()" [SKFLayoutInputDetails]="SKFLayoutInputDetails" [name]="name">
      <router-outlet></router-outlet>
    </lib-skf-ui-layout>
  `
})
export class AdminComponent {
  private SKFHeaderDetails: SKFHeaderInputDetails;
  private SKFFooterDetails: SKFFooterInputDetails;
  public SKFLayoutInputDetails: SKFLayoutInputDetails;
  public name: string;

  constructor(private auth: SKFAuthService, private uiCommon: SKFUICommonService,
    private router: Router) {
    // setting up header details
    this.SKFHeaderDetails = {
      firstName: this.auth.userDetails.FirstName,
      lastName: this.auth.userDetails.LastName,
      logoRedirectURL: '/',
      logoImgURL: 'assets/images/logo.png',
      enableLangSwitching: true,
      productName: ''
    };
    // setting up footer details
    this.SKFFooterDetails = {
      footerText: 'footer',
      siteURL: 'SKFLink.com'
    };
    // finalizing layout input
    this.SKFLayoutInputDetails = {
      enableNgxLoader: false,
      enablePerfectScrollbar: true,
      headerDetails: this.SKFHeaderDetails,
      footerDetails: this.SKFFooterDetails
    };
    // updating permissions and menu items in skf ui common service
    this.uiCommon.permissions = this.auth.getIdentity();
    this.uiCommon.menuItems = this.setMenuItems();
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        const curPath = this.router.url.toString().split('/');
        const curModule = curPath[1];
        this.name = moduleLabels.get(curModule)  
      }
    });
  }

  private setMenuItems() {
    const menuArr = JSON.parse(JSON.stringify(menuItems));
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const pages = Object.keys(permissions).filter(k => k.startsWith('page_questions_') && permissions[k] > 0);
    const permittedQuestionCatgs = [];
    pages.forEach((key) => {
      if (key !== 'page_questions_approve' && key !== 'page_questions_moderate' && key !== 'page_questions_private') {
        permittedQuestionCatgs.push(key.substring(15));
      }
    });
    localStorage.setItem("permitted_question_categories", permittedQuestionCatgs.join());
    const isQuestionTriageAdmin = permittedQuestionCatgs.includes('triage');
    if (!isQuestionTriageAdmin) {
      const index = menuArr.findIndex((item) => (item.label === 'Communication'));
      if (index > -1) {
        menuArr[index].navItems = [
          {
            icon: 'approve',
            name: 'Approved',
            link: 'questions/questions_approve'
          },
          {
            icon: 'private',
            name: 'Private',
            link: 'questions/questions_private'
          }
        ]
      }
    }

    return menuArr;
  }
  
  public logout() {
    this.auth.logout();
  }
}

import {Injectable} from '@angular/core';
import {SKFIdentity} from '@app/_interfaces/SKFIdentity.interface';
import {SharedService} from '@app/services/shared.service';
import {SKFAppSecGroupsLink, SKFAppSecJobFunctionsLink, SKFAppSecUsersLink} from '@app/_config/externalLinks';
import {SKFAuthService} from '@skf/auth';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  main_state?: string;
  target?: boolean;
  external?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  type: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Notification',
    type: 'internal',
    main: [{
      state: 'notifications',
      name: 'Manage Notifications',
      type: 'sub',
      icon: 'fa fa-bell-o',
      children: [
        {
          state: 'compose',
          name: 'Compose',
          type: 'link',
          icon: 'ti-crown'
        }, {
          state: 'sent',
          name: 'Sent',
          type: 'link',
          icon: 'ti-crown'
        },
        {
          state: 'saved',
          name: 'Saved',
          type: 'link',
          icon: 'ti-crown'
        },
        {
          state: 'scheduled',
          name: 'Scheduled',
          type: 'link',
          icon: 'ti-crown'
        }
      ]
    }]
  },
  {
    label: 'User Maintenance',
    type: 'external',
    main: [
      {
        external: `${SKFAppSecGroupsLink}`,
        target: '_blank',
        state: 'groups',
        name: 'Manage Groups',
        type: 'external',
        icon: 'group'
      },
      {
        external: `${SKFAppSecJobFunctionsLink}`,
        target: '_blank',
        state: 'jobfunctions',
        name: 'Manage Functions',
        type: 'external',
        icon: 'work_outline'
      },
      {
        external: `${SKFAppSecUsersLink}`,
        target: '_blank',
        state: 'users',
        name: 'Manage Users',
        type: 'external',
        icon: 'person',
      }]
  },
  {
    label: 'Category',
    type: 'internal',
    main: [{
      state: 'categories',
      name: 'Manage Categories',
      type: 'link',
      icon: 'ti-tag'
    }]
  },
  {
    label: 'Communication',
    type: 'internal',
    main: [
      {
        state: 'questions',
        name: 'Manage Questions',
        type: 'sub',
        icon: 'ti-comment',
        children: [
          {
            state: 'questions_moderate',
            name: 'Moderate Questions',
            type: 'link',
            icon: 'ti-crown'
          }, {
            state: 'questions_approve',
            name: 'Approved Questions',
            type: 'link',
            icon: 'ti-crown'
          }, {
            state: 'questions_private',
            name: 'Private Questions',
            type: 'link',
            icon: 'ti-crown'
          }
        ]
      }
    ]
  },
  {
    label: 'Survey',
    type: 'internal',
    main: [
      {
        state: 'surveys',
        name: 'Manage Surveys',
        type: 'link',
        icon: 'ti-pencil-alt'
      }
    ]
  },
  {
    label: 'Time Offs',
    type: 'internal',
    main: [
      {
        state: 'timeoff',
        name: 'Manage Time-Offs',
        type: 'sub',
        icon: 'ti-time',
        children: [
          {
            state: 'manage',
            name: 'Manage Time-Offs',
            type: 'link',
            icon: 'ti-time'
          },
          {
            state: 'manage-accrual',
            name: 'Manage Accrual Profiles',
            type: 'link',
            icon: 'ti-bolt-alt'
          }
        ]
      }]
  },
  {
    label: 'Mobile App',
    type: 'internal',
    main: [{
      state: 'links',
      name: 'Manage App',
      type: 'link',
      icon: 'ti-mobile'
    }]
  },
  {
    label: 'FAQ',
    type: 'internal',
    main: [{
      state: 'faq',
      name: 'Manage FAQs',
      type: 'link',
      icon: 'ti-help'
    }]
  },
  {
    label: 'News',
    type: 'internal',
    main: [
      {
        state: 'news',
        name: 'Manage Events',
        type: 'link',
        icon: 'ti-rss'
      }
    ]
  }
] as Array<Menu>;

@Injectable()
export class MenuItems {
  menuItems = MENUITEMS;
  private identity: SKFIdentity;

  constructor(private skfAuthService: SKFAuthService) {
  }

  getAll(): Menu[] {
    this.identity = this.skfAuthService.getIdentity();
    this.menuItems = this.restrictMenuItems();
    return this.menuItems;
  }

  private restrictMenuItems(): Array<Menu> {
    // restrict modules by removing inaccessible modules from menu items
    const allowedMenuItems = this.restrictModules(MENUITEMS);
    // if module has child pages
    /* allowedMenuItems.forEach(x => {
       if (x.main[0].children) {
         // restrict inaccessible pages from module
         x.main[0].children = this.restrictChildren(x.main[0]);
       }
     });*/
    return allowedMenuItems;
  }

  private restrictModules(items: Array<Menu>) {
    const restrictedItems = [];
    items.forEach(x => {
      const restrictedItem = x;
      const restrictedMains = [];
      restrictedItem.main.forEach(y => {
        if (this.identity.modules.get(y.state) === 1) {
          restrictedMains.push(y);
        }
      });
      if (restrictedMains.length > 0) {
        restrictedItem.main = restrictedMains;
        restrictedItems.push(restrictedItem);
      }
    });
    return restrictedItems;
    // return items;
  }

  private restrictChildren(item: MainMenuItems) {
    return item.children.filter(y => this.identity.pages.get(y.state) === 1);
  }
}

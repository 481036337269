<app-skf-autocomplete [label]="'Timeoffs'"
                      [placeholder]="placeholder"
                      secondaryPlaceholder="Add More TimeOffs"
                      [idKey]="'id'"
                      [labelKey]="'name'"
                      [required]="required"
                      [readonly]="readonly"
                      (emitSelectedItems)="timeOffsSelected($event)"
                      [modalMode]="true"
                      (emitToggleModalView)="openSearchModalView(searchModalView)"
                      (scrollDisable)="doScrollEnable($event)"
                      [selectedItems]="selectedTimeOffs"
                      [autoCompleteFunction]="searchApiAccessor.bind(this)"
></app-skf-autocomplete>


<ng-template #searchModalView>
  <app-modal [action]="'Confirm'"
             type="content"
             [isValid]="modalViewTimeOffs?.length>0"
             (dismiss)="closeModal()"
             (proceed)="updateSelectedTimeoffs($event)"
  >
    <app-skf-search-modal-view
      *ngIf="canShowSKFSearchModalInput"
      name="timeoffs"
      [selectedItems]="modalViewTimeOffs"
      [autoCompleteFunction]="searchApiAccessor.bind(this)"
      (emitSelectedItems)="updateModalViewTimeoffs($event)"
    ></app-skf-search-modal-view>
  </app-modal>
</ng-template>

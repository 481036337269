<section>
    <div class="skf-card-header" fxLayout="row" fxLayoutGap="20px">
        <ng-container *ngIf="tableMode; else normalMode">
            <div *ngIf="isActionBtn" (click)="doActionClick()" [matTooltip]="actionToolTip" matTooltipPosition="above" class="action-wrapper">
                <mat-icon class="action-icon" matPrefix>{{actionIcon}}</mat-icon>
                <span>{{actionTitle}}</span>
            </div>
            <div class="skf-card-search">
                <mat-form-field class="card-search" appearance="none">
                    <mat-icon matPrefix>search</mat-icon>
                    <input [formControl]="searchControl" name="search" matInput type="search" autocomplete="off"
                        placeholder="Search" class="search-input">
                    <button mat-button *ngIf="searchControl.value" matSuffix mat-icon-button aria-label="Clear"
                        (click)="emitClear()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </ng-container>
        <ng-template #normalMode>
            <span *ngIf="showBackArrow">
                <button mat-icon-button (click)="goBack()">
                    <mat-icon>arrow_back</mat-icon>
                </button>
            </span>
            <span class="skf-card-title" *ngIf="title">{{title|uppercase}}</span>
        </ng-template>
    </div>
    <div class="skf-card-body" [class.table-mode]="tableMode">
        <ng-content></ng-content>
    </div>
</section>
import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-skf-text-area',
  templateUrl: './skf-text-area.component.html',
  styleUrls: ['./skf-text-area.component.scss']
})
export class SkfTextAreaComponent implements OnInit {
  @Input() name: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() rows = 4;
  @Input() cols = 50;
  @Input() required = false;
  @Input() control: FormControl;
  @ViewChild('textAreaInput', {static: true}) textAreaInput: ElementRef;

  constructor() {
  }

  ngOnInit() {
  }

  public focus() {
    (this.textAreaInput.nativeElement as HTMLInputElement).focus();
  }


}

// {module,label}
export const moduleLabels = new Map([
  ['notifications', 'Notifications'],
  ['categories', 'Category'],
  ['questions', 'Communication'],
  ['surveys', 'Survey'],
  ['timeoff', 'Time Offs'],
  ['faq', 'FAQ'],
  ['links', 'Mobile App'],
  // ['news', 'News'],
  ['organnouncement', 'Org Announcement'],
  ['colleague', 'Colleague Corner'],
  ['analytics', 'Analytics'],
  ['newshub', 'News Hub'],
]);

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-skf-toggle',
  templateUrl: './skf-toggle.component.html',
  styleUrls: ['./skf-toggle.component.scss']
})
export class SkfToggleComponent implements OnInit {
  @Input() isOn = false;
  @Input() name = '';
  @Input() onText = 'active';
  @Input() offText = 'inactive';
  @Input() disabled = false;
  @Output() changeEvent = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit() {
  }

  public emitChange(event) {
    this.changeEvent.emit(event.target.checked);
  }

}

<app-skf-modal
  [title]="title"
  [dialog]="message"
  [type]="type"
  [icon]="icon"
  [confirmText]="action"
  [isNotValid]="!isValid"
  (closeEvent)="onDismiss()"
  (cancelEvent)="onAction(false)"
  (confirmEvent)="onAction(true)">
  <ng-container *ngIf="type==='content'">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-container>
</app-skf-modal>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

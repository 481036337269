import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Location} from '@angular/common';
import {FormControl} from '@angular/forms';
import {debounceTime, filter, tap} from 'rxjs/operators';

@Component({
  selector: 'app-skf-card',
  templateUrl: './skf-card.component.html',
  styleUrls: ['./skf-card.component.scss']
})
export class SkfCardComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() showBackArrow = false;
  @Input() tableMode = false;
  searchControl = new FormControl();
  @Output() searchEvent = new EventEmitter<string>();

  constructor(private location: Location) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.tableMode) {
      this.searchControl.valueChanges.pipe(
        tap(val => {
          if (val === '') {
            this.searchEvent.emit(val);
          }
        }),
        filter(val => val.trim() !== '' && (val.trim().length >= 3 && val.length <= 3998)),
        debounceTime(500),
      ).subscribe(val => this.searchEvent.emit(val));
    }
  }

  goBack() {
    this.location.back();
  }

  emitClear() {
    this.searchControl.setValue('');
    this.searchEvent.emit('');
  }

}

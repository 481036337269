import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, of} from 'rxjs';
import {flatMap} from 'rxjs/operators';
import {SharedService} from '@app/services/shared.service';
import {SKFTimeoff} from '@app/_interfaces/SKFTimeoff.interface';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-timeoff-search-field',
  templateUrl: './timeoff-search-field.component.html'
})
export class TimeoffSearchFieldComponent implements OnInit {
  @Input() selectedTimeOffs = [] as Array<SKFTimeoff>;
  public modalViewTimeOffs = [] as Array<SKFTimeoff>;
  @Input() placeholder = 'Add TimeOff';
  @Input() required = false;
  @Input() readonly = false;
  public inError = false;
  public searchModalRef: NgbModalRef;
  public canShowSKFSearchModalInput: boolean
  @Output() onTimeOffSelected = new EventEmitter<Array<SKFTimeoff>>();

  constructor(public sharedService: SharedService,
              public modalService: NgbModal) {
  }

  ngOnInit() {
  }

  public timeOffsSelected(event?: Array<any>) {
    event ? this.onTimeOffSelected.emit(event)
      : this.onTimeOffSelected.emit(this.selectedTimeOffs);
  }

  public checkError() {
    if (this.required) {
      this.inError = this.selectedTimeOffs.length === 0;
    }
  }

  public searchApiAccessor(val: string): Observable<any> {
    return this.sharedService.searchTimeOffs(val).pipe(
      flatMap((res: any) => {
        return of(res.data);
      })
    );
  }

  public doScrollEnable(event: boolean) {
    this.sharedService.scrollDisabledSubject.next(event);
  }

  public openSearchModalView(template) {
    this.canShowSKFSearchModalInput = false;
    this.modalViewTimeOffs = [...this.selectedTimeOffs];
    this.searchModalRef = this.modalService.open(template);
    setTimeout(() => {
      this.canShowSKFSearchModalInput = true;
    }, 10);
  }

  public closeModal() {
    this.searchModalRef.close();
  }

  public updateSelectedTimeoffs(update: boolean) {
    if (update) {
      this.selectedTimeOffs = this.modalViewTimeOffs;
      this.timeOffsSelected();
    }
    this.closeModal();
  }

  public updateModalViewTimeoffs(event) {
    this.modalViewTimeOffs = event;
  }

}

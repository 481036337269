import {SKFMenuItem} from '@skf/ui';
import {SKFAppSecGroupsLink, SKFAppSecJobFunctionsLink, SKFAppSecUsersLink} from '@app/_config/externalLinks';

export const menuItems = [
    {
      label: 'Notification',
      type: 'internal',
      state: 'notifications',
      navItems: [{
        icon: 'compose',
        name: 'Compose',
        link: 'notifications/compose'
      },
        {
          icon: 'sent',
          name: 'Sent',
          link: 'notifications/sent'
        },
        {
          icon: 'saved',
          name: 'Saved',
          link: 'notifications/saved'
        },
        {
          icon: 'scheduled',
          name: 'Scheduled',
          link: 'notifications/scheduled'
        }]
    },
    {
      label: 'User Maintenance',
      type: 'external',
      navItems: [
        {
          link: `${SKFAppSecGroupsLink}`,
          name: 'Manage Groups',
          icon: 'group'
        },
        {
          link: `${SKFAppSecJobFunctionsLink}`,
          name: 'Manage Functions',
          icon: 'work_outline'
        },
        {
          link: `${SKFAppSecUsersLink}`,
          name: 'Manage Users',
          icon: 'person',
        }]
    },
    {
      label: 'Category',
      type: 'internal',
      state: 'categories',
      navItems: [
        {
          icon: 'add',
          name: 'Create',
          link: 'categories/add'
        },
        {
          icon: 'layers',
          name: 'Manage',
          link: 'categories/manage'
        },
        // {
        //   icon: 'add',
        //   name: 'New',
        //   link: 'categories/new'
        // },
      ]
    },
    {
      label: 'Communication',
      type: 'internal',
      state: 'questions',
      navItems: [
        {
          icon: 'moderate',
          name: 'Moderate',
          link: 'questions/questions_moderate'
        },
        {
          icon: 'approve',
          name: 'Approved',
          link: 'questions/questions_approve'
        },
        {
          icon: 'private',
          name: 'Private',
          link: 'questions/questions_private'
        }
      ]
    },
    {
      label: 'Survey',
      type: 'internal',
      state: 'surveys',
      navItems: [
        {
          icon: 'add',
          name: 'Create',
          link: 'surveys/create'
        },
        {
          icon: 'layers',
          name: 'Manage',
          link: 'surveys/list'
        }]
    },
    {
      label: 'Time Offs',
      type: 'internal',
      state: 'timeoff',
      navItems: [
        {
          icon: 'add',
          name: 'Create TimeOff',
          link: 'timeoff/create'
        },
        {
          icon: 'layers',
          name: 'Manage TimeOffs',
          link: 'timeoff/manage'
        },
        {
          icon: 'add',
          name: 'Create Accrual',
          link: 'timeoff/create-accrual'
        },
        {
          icon: 'toll',
          name: 'Manage Accrual',
          link: 'timeoff/manage-accrual'
        }
      ]
    },
    {
      label: 'Mobile App',
      type: 'internal',
      state: 'links',
      navItems: [
        {
          icon: 'phone',
          name: 'Manage',
          link: 'links/manage'
        }
      ]
    },
    // {
    //   label: 'FAQ',
    //   type: 'internal',
    //   state: 'faq',
    //   navItems: [
    //     {
    //       icon: 'add',
    //       name: 'Create',
    //       link: 'faq/add'
    //     },
    //     {
    //       icon: 'help',
    //       name: 'Manage',
    //       link: 'faq/manage'
    //     }
    //   ]
    // },
    // {
    //   label: 'News',
    //   type: 'internal',
    //   state: 'news',
    //   navItems: [
    //     {
    //       icon: 'add',
    //       name: 'Create',
    //       link: 'news/create'
    //     },
    //     {
    //       icon: 'scheduled',
    //       name: 'Manage',
    //       link: 'news/list'
    //     }
    //   ]
    // },
    {
      label: 'News Hub',
      type: 'internal',
      state: 'newshub',
      navItems: [
        {
          icon: 'layers',
          name: 'Manage',
          link: 'newshub/manage'
        }
      ]
    },
    {
      label: 'Org Announcement',
      type: 'internal',
      state: 'organnouncement',
      navItems: [
        {
          icon: 'layers',
          name: 'Manage',
          link: 'organnouncement/manage'
        }
      ]
    },
    {
      label: 'Colleague Corner',
      type: 'internal',
      state: 'colleague',
      navItems: [
        {
          icon: 'layers',
          name: 'Manage',
          link: 'colleague/manage'
        }
      ]
    },
    {
      label: 'Analytics',
      type: 'internal',
      state: 'analytics',
      navItems: [
        {
          icon: 'layers',
          name: 'Home',
          link: 'analytics/home'
        }
      ]
    }
  ] as Array<SKFMenuItem>
;

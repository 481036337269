<app-skf-autocomplete [label]="'Functions'"
                      [placeholder]="placeholder"
                      secondaryPlaceholder="Add More Functions"
                      [idKey]="'id'"
                      [labelKey]="'name'"
                      [required]="required"
                      [readonly]="readonly"
                      (emitSelectedItems)="functionSelected($event)"
                      [modalMode]="true"
                      (emitToggleModalView)="openSearchModalView(searchModalView)"
                      (scrollDisable)="doScrollEnable($event)"
                      [selectedItems]="selectedFunction"
                      [autoCompleteFunction]="searchApiAccessor.bind(this)"
></app-skf-autocomplete>

<ng-template #searchModalView>
  <app-modal [action]="'Confirm'"
             type="content"
             [isValid]="modalViewFunctions?.length>0"
             (dismiss)="closeModal()"
             (proceed)="updateSelectedFunctions($event)"
  >
    <app-skf-search-modal-view
      *ngIf="canShowFunctionSearchInput"
      name="functions"
      [selectedItems]="modalViewFunctions"
      [autoCompleteFunction]="searchApiAccessor.bind(this)"
      (emitSelectedItems)="updateModalViewFunctions($event)"
    ></app-skf-search-modal-view>
  </app-modal>
</ng-template>

import {Component, Input, OnInit} from '@angular/core';
import {SKFViewDetails} from '../../interfaces/SKFViewDetails.interface';

@Component({
  selector: 'app-skf-view-card',
  templateUrl: './skf-view-card.component.html',
  styleUrls: ['./skf-view-card.component.scss']
})
export class SkfViewCardComponent implements OnInit {
  @Input() cardTitle = '';
  @Input() dateFormat = 'MM/dd/yyyy';
  @Input() entityTitle = '';
  @Input() viewDetails: Array<SKFViewDetails>;

  constructor() {
  }

  ngOnInit() {
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, of} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, flatMap} from 'rxjs/operators';
import {SharedService} from '@app/services/shared.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-functions-search-field',
  templateUrl: './functions-search-field.component.html'
})
export class FunctionsSearchFieldComponent implements OnInit {
  @Input() selectedFunction = [] as Array<any>;
  public modalViewFunctions = [] as Array<any>;
  @Input() placeholder = 'Add Function';
  @Input() required = false;
  @Input() width = '100%';
  @Input() readonly = false;
  public inError = false;
  public searchModalRef: NgbModalRef;
  public canShowFunctionSearchInput: boolean
  @Output() onFunctionSelected = new EventEmitter();

  constructor(public sharedService: SharedService,
              public modalService: NgbModal) {
  }

  ngOnInit() {
  }

  public functionSelected(event?: Array<any>) {
    event ? this.onFunctionSelected.emit(event)
      : this.onFunctionSelected.emit(this.selectedFunction);
  }

  public checkError() {
    if (this.required) {
      this.inError = this.selectedFunction.length === 0;
    }
  }

  public searchApiAccessor(val: string): Observable<any> {
    return this.sharedService.searchFunctions(val).pipe(
      flatMap((res: any) => {
        return of(res.data);
      })
    );
  }

  public doScrollEnable(event: boolean) {
    this.sharedService.scrollDisabledSubject.next(event);
  }

  public openSearchModalView(template) {
    this.canShowFunctionSearchInput = false;
    this.modalViewFunctions = [...this.selectedFunction];
    this.searchModalRef = this.modalService.open(template);
    setTimeout(() => {
      this.canShowFunctionSearchInput = true;
    }, 10);
  }

  public closeModal() {
    this.searchModalRef.close();
  }

  public updateSelectedFunctions(update: boolean) {
    if (update) {
      this.selectedFunction = this.modalViewFunctions;
      this.functionSelected();
    }
    this.closeModal();
  }

  public updateModalViewFunctions(event) {
    this.modalViewFunctions = event;
  }

}

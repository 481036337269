import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { SKFAuthService } from '@skf/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private authService: SKFAuthService,
    private router: Router,
    ) {
    
  }
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isTokenInvalid()) {
      return true;
    } else {
      this.router.navigate(['/notifications']);
      return false;
    }
  }
  
}

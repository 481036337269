import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpResponse} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {LoaderService} from './loader.service';
import {SKFAuthInterceptor, SKFAuthService} from '@skf/auth';

@Injectable()
export class InterceptorService extends SKFAuthInterceptor implements HttpInterceptor {

  constructor(
    private skfAuthService: SKFAuthService,
    private loaderService: LoaderService
    ) {
    super(skfAuthService);
    super.performLogoutOnError = false;
  }

  intercept(req, next) {
    // Show loader if not search request
    if (!(req.url.includes('?q'))) {
      this.loaderService.show();
    }
    // TRIM request content strings
    if (req.body) {
      Object.keys(req.body).forEach(key => {
        if (req.body[key] && typeof req.body[key] === 'string') {
          req.body[key] = req.body[key].trim();
        }
      });
    }

    //super.addFilters(['abhsihek','paliwal'])
    return super.intercept(req, next).pipe(
      // hide loader when request completes in success
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.loaderService.hide();
        }
      }),
      catchError(error => {
        // hide loader if request completes in error
        this.loaderService.hide();
        return of(error);
      }) as any) as Observable<HttpEvent<any>>;
  }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {catchError} from 'rxjs/operators';
import {SKFModalInput, SKFUICommonService, SKFUIModalService} from '@skf/ui';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  value = new BehaviorSubject<boolean>(false);
  scrollDisabledSubject = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient,
              private modalService: SKFUIModalService,
              private skfCommon: SKFUICommonService) {
  }

  set modalValue(value: any) {
    this.value.next(value);
  }

  get modalValue() {
    return this.value.asObservable();
  }

  public updateNavItems(countData) {
    this.skfCommon.updateNavItems(countData);
  }

  // region Search API Calls
  // To search groups
  public searchGroups(keyword) {
    return this.http
      .get<any>(`${environment.baseURL}skf/link/group?q=${keyword}`)
      .pipe(catchError(err => throwError(err)));
  }

  // To search functions
  public searchFunctions(keyword) {
    return this.http
      .get<any>(`${environment.functionBaseURL}skf/link/jobfunction?q=${keyword}`)
      .pipe(catchError(err => throwError(err)));
  }

  // To search users for group assignment
  public searchUsersForGroup(keyword, gid) {
    return this.http
      .get<any>(`${environment.baseURL}skf/link/user?q=${keyword}&gid=${gid}&is_active=1`)
      .pipe(catchError(err => throwError(err)));
  }

  // To Search Active Users
  public searchActiveUsers(keyword) {
    return this.http.get<any>(`${environment.baseURL}skf/link/user?q=${keyword}&is_active=1`);
  }

  // To Search Unassigned Users
  public searchUnassignedUsers(keyword) {
    return this.http.get<any>(`${environment.functionBaseURL}skf/link/jobfunction/user/unassigned?q=${keyword}&is_active=1`);
  }

  // To search functions
  public searchTimeOffs(keyword) {
    return this.http
      .get<any>(`${environment.timeOffBaseURL}skf/link/timeoff?q=${keyword}`)
      .pipe(catchError(err => throwError(err)));
  }

  // endregion

  // region skf-ui modal handling
  public openModal(data: SKFModalInput) {
    return this.modalService.openDialog(data);
  }

  // endregion
}

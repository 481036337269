import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SharedService} from 'src/app/services/shared.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  @Input() message;
  @Input() action;
  @Input() title;
  @Input() isValid = true;
  @Input() type = 'dialog';
  @Input() icon: string;
  @Output() proceed = new EventEmitter();
  @Output() dismiss = new EventEmitter();

  constructor(public activeModal: NgbActiveModal,
              public sharedService: SharedService) {
  }

  ngOnInit() {
  }

  onAction(val: boolean) {
    this.proceed.emit(val);
    if (val) {
      this.sharedService.modalValue = val;
    }
    this.activeModal.close('Close click');
  }

  onDismiss() {
    this.dismiss.emit();
    this.activeModal.dismiss('Cross click');
  }
}

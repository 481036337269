import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {debounceTime, filter, flatMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-skf-search-modal-view',
  templateUrl: './skf-search-modal-view.component.html',
  styleUrls: ['./skf-search-modal-view.component.scss']
})
export class SkfSearchModalViewComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() labelKey = 'name';
  @Input() valueKey = 'id';
  @Input() readonly = false;
  public formControl = new FormControl();
  @Input() autoCompleteFunction: (text: string) => Observable<any>;
  @ViewChild('searchControl', {static: true}) searchControl: ElementRef<HTMLInputElement>;

  public filteredItems: Observable<Array<any>>;
  @Input() public selectedItems = [] as Array<any>;

  @Output() emitSelectedItems = new EventEmitter();

  constructor() {
    this.formControl.valueChanges.pipe(
      filter(val => typeof val === 'string'),
      tap(val => {
        if (val === '') {
          this.filteredItems = of([]);
        }
      }),
      filter(val => val.trim() !== '' && val.trim().length >= 3),
      debounceTime(500)).subscribe(val => {
      this.filteredItems = this.autoCompleteFunction(val).pipe(
        flatMap(res => of(this.filterResponse(res)))
      );
    });
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.autoCompleteFunction.firstChange) {
      this.focus();
    }
  }

  public focus() {
    this.searchControl.nativeElement.focus();
  }

  public updateSelection(item, checked: boolean) {
    checked ? this.add(item) : this.remove(item);
  }

  public add(item) {
    this.selectedItems.push(item);
    this.emitItems();
  }

  public remove(item) {
    if (this.getItemIndex(item) >= 0) {
      this.selectedItems.splice(this.getItemIndex(item), 1);
      this.emitItems();
    }
  }

  private getItemIndex(item) {
    return this.selectedItems.findIndex(x => x[this.valueKey] === item[this.valueKey]);
  }

  private filterResponse(res: Array<any>): any[] {
    return this.selectedItems.length > 0 ? res.filter(x => !this.selectedItems.find(y => y[this.valueKey] === x[this.valueKey])) : res;
  }

  private emitItems() {
    this.emitSelectedItems.emit(this.selectedItems);
  }

}
